<template>
    <el-main>
        <div class="searchdiv">
            <div class="searchform">
                <el-input v-model="searchinfo.ip" :placeholder="$t('iplist.Ip')" :label="$t('iplist.Ip')" clearable></el-input>
                <el-button type="primary" @click="dosearch()" icon="el-icon-search" :disabled="isbusy" :loading="isbusy">{{$t('global.search')}}</el-button>
            </div>
            <div>
                <el-button type="primary" @click="adduser('userform')" icon="el-icon-plus">{{ $t('global.add') }}{{ $t('iplist.Ip') }}</el-button>
            </div>
        </div>
        <div class="pagecontent">
            <el-table ref="multipleTable"
                      :data="list"
                      tooltip-effect="dark"
                      style="width: 100%">
                <el-table-column
                        :label="$t('iplist.Ip')"
                        width="120" prop="ip">
                </el-table-column>
                <el-table-column
                        prop="remark"
                        :label="$t('iplist.remark')"
                        >
                </el-table-column>
                <el-table-column
                        :label="$t('iplist.created_at')"
                        width="170" prop="created_at">
                </el-table-column>
                <el-table-column :label="$t('global.action')" fixed="right">
                    <template slot-scope="scope">
                        <el-link class="optlink"  @click="deluser(scope.row)">{{$t('global.delete')}}</el-link>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    background
                    @size-change="SizeChange"
                    @current-change="getlist"
                    :current-page="searchinfo.page"
                    :page-sizes="PAGE.pageSizes"
                    :page-size="searchinfo.pagesize"
                    :layout="PAGE.layout"
                    :total="totalRows">
            </el-pagination>
        </div>
        <el-dialog :title="$t('iplist.addIp')" :visible.sync="isshowdialog" :close-on-click-modal="false">
            <el-form :model="userformdata" :rules="rules" ref="userform" :show-message="false">
                <el-form-item :label="$t('iplist.Ip')" label-width="120px" prop="ip" required>
                    <el-input v-model="userformdata.ip" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label="$t('iplist.remark')" label-width="120px" prop="remark">
                    <el-input type="textarea" v-model="userformdata.remark"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">{{$t('global.cancel')}}</el-button>
                <el-button type="primary" @click="dosubmit('userform')" :disabled="isbusy" :loading="isbusy">{{$t('global.submit')}}</el-button>
            </div>
        </el-dialog>
    </el-main>
</template>

<script>
    // @ is an alias to /src

    export default {
        name: 'user',
        components: {},
        data() {
            return {
                searchinfo: {
                    page:1,
                    pagesize: this.PAGE.pageSize,
                    ip: ''
                },
                list: [],
                multipleSelection: [],
                totalRows: 0,
                defaultuserinfo: {
                    id: 0,
                    ip: '',
                    remark: '',
                },
                userformdata: {},
                rules: {},
                isshowdialog: false,
                isbusy: false,
            }
        },
        methods: {
            SizeChange(val){
                this.searchinfo.pagesize=val;
                this.getlist(1);
            },
            adduser(formName){
                this.userformdata = this.cloneData(this.defaultuserinfo);
                this.isshowdialog=true;
                this.$nextTick(()=>{
                    this.$refs[formName].resetFields();
                });
            },
            async dosearch(){
                this.getlist(1);
            },
            async getlist(page){
                this.searchinfo.page=page||this.searchinfo.page;
                this.isbusy = true;
                let res= await this.httpget('/system/ip/index',this.searchinfo);
                if(res.code===0){
                    this.list=res.data.data;
                    this.totalRows = res.data.total;
                    this.isbusy = false;
                } else {
                    this.isbusy = false;
                }

            },
            dosubmit(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.suresubmit();
                    } else {
                        return false;
                    }
                });
            },
            async suresubmit(){
                this.isbusy = true;
                let res= await this.httppost('/system/ip/save',this.userformdata);
                if(res.code===0){
                    this.$message({type: 'success', message: res.msg});
                    this.getlist(1);
                    this.userformdata={};
                    this.isshowdialog=false;
                    this.isbusy = false;
                }else{
                    this.isbusy = false;
                    this.$message({type: 'error', message: res.msg});
                }
            },
            async deluser(item){
                this.$confirm(this.$t('global.areyousure')).then( async () => {
                    this.isbusy = true;
                    let res= await this.httpput('/system/ip/delete',{id:item.id});
                    if(res.code===0){
                        this.$message({type: 'success', message: res.msg});
                        this.getlist();
                    }else{
                        this.$message({type: 'error', message: res.msg});
                    }
                    this.isbusy = false;
                }).catch(() => {
                    this.isbusy = false;
                    this.$message({type: 'info', message: 'Canceled'});
                });
            }
        },
        mounted() {
            this.getlist(1);
        },
        computed: {
            baseurl: {
                get: function() {
                    return this.$store.getters.getSerdomain;
                }
            },
        },
    }
</script>

<style>
    .uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 50px;
        line-height: 50px;
        text-align: center;
    }
    .preview {
        width: 178px;
        height: 50px;
        display: block;
    }
</style>
